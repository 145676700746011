<script context="module">
	import { FocusController2 } from '$lib/experimental/utils/FocusController2.js';

	class MenuFocusController extends FocusController2 {
		/**
		 * @param {HTMLElement} itemElement
		 * @returns {HTMLElement[]}
		 */
		getSiblingElements(itemElement) {
			const ul = itemElement.parentElement?.parentElement ?? null;
			if (ul === null) {
				return [];
			}
			return /** @type {HTMLElement[]} */ (
				Array.from(ul.children)
					.map((li) => li.firstElementChild)
					.filter((el) => el !== null)
			);
		}
	}

	let nextId = 0;
</script>

<script>
	import { Icon } from '@ctm/icons';
	import { ChevronDown } from '@ctm/icons/directional';
	import styles from '$lib/Button/Button.module.css';
	import Popup from '$lib/Popup';
	import Stack from '$lib/Stack';
	// import { getChildProps } from '$lib/experimental/utils/getChildProps.js';
	import menuStyles from './Menu.module.css';
	import Menu from './Menu.svelte';

	/** @typedef {Partial<Pick<ComponentProps<Popup>, 'class' | 'boundary' | 'padding' | 'placement'>>} PopupOptions */

	let className = '';
	export { className as class };

	/** @type {IconData | undefined} */
	export let icon = undefined;

	export let iconOnly = false;

	/** @type {string | undefined} */
	export let label = undefined;

	/** @type {string | undefined} */
	export let description = undefined;

	export let disabled = false;

	/** @type {PopupOptions} */
	const defaultPopupOptions = { class: menuStyles['menu-popup'], placement: 'bottom-end' };

	/** @type {PopupOptions | undefined} */
	let popupOptions = defaultPopupOptions;
	export { popupOptions as popup };

	/** @type {IconData | undefined} */
	export let triggerIcon = ChevronDown;

	/** @type {'xs' | 'sm' | 'md' | 'lg' | undefined} */
	export let size = undefined;

	/** @type {'ghost' | 'outline' | 'solid' | undefined} */
	export let style = undefined;

	/** @type {'active' | 'danger' | 'neutral' | 'success' | undefined} */
	export let tone = undefined;

	const id = 'menu-button-' + (nextId++).toString();
	const menuId = id + '-menu';

	let expanded = false;

	const focus = new MenuFocusController();

	/** @type {Menu} */
	let menu;

	/** @type {Popup} */
	let popup;

	/** @type {'sm' | 'md' | undefined} */
	$: iconSize = !size ? undefined : size === 'xs' || size === 'sm' ? 'sm' : iconOnly ? 'md' : 'sm';

	// $: childProps = getChildProps(['menu'], $$restProps);

	function expand() {
		if (expanded) {
			return;
		}
		menu.expand();
		popup.show();
	}

	function collapse() {
		if (!expanded) {
			return;
		}
		menu.collapse();
		popup.hide();
	}

	function toggle() {
		if (expanded) {
			collapse();
		} else {
			expand();
		}
	}

	const expandOn = new Set(['Enter', ' ', 'ArrowDown', 'ArrowUp']);

	/** @param {HTMLEvent<KeyboardEvent, HTMLButtonElement>} event */
	function handleKeyDown(event) {
		if (expanded) {
			switch (event.key) {
				case 'Escape':
					// collapse();
					break;
				case 'ArrowDown':
					focus.next();
					break;
				case 'ArrowLeft':
					if (focus.stack.length > 0) {
						focus.stack.at(-1)?.click();
					}
					break;
				case 'ArrowRight':
					if (focus.focusedElement?.ariaHasPopup === 'menu') {
						focus.focusedElement.click();
					}
					break;
				case 'ArrowUp':
					focus.previous();
					break;
				case 'PageDown':
					focus.last();
					break;
				case 'PageUp':
					focus.first();
					break;
				case 'Enter':
				case ' ': {
					focus.focusedElement?.click();
					break;
				}
				default:
					// if (!typeahead.handleKey(event)) {
					// 	return;
					// }
					return;
			}
			event.preventDefault();
		} else if (expandOn.has(event.key)) {
			event.preventDefault();
			expand();
		}
	}

	/** @param {HTMLEvent<PointerEvent, HTMLButtonElement>} _event */
	function handlePointerDown(_event) {
		toggle();
	}

	$: console.debug('[MenuButton] $focus = %o', $focus);
</script>

<button
	{id}
	class="{styles.button} {className}"
	aria-controls={menuId}
	aria-disabled={disabled}
	aria-expanded={expanded}
	aria-haspopup="menu"
	data-icon-only={iconOnly ? true : undefined}
	data-size={size}
	data-style={style}
	data-tone={tone}
	type="button"
	{...$$restProps}
	on:keydown={handleKeyDown}
	on:pointerdown={handlePointerDown}
>
	{#if icon}
		<Icon class={styles.icon} {icon} size={iconSize} />
	{/if}
	{#if label}
		<Stack align="left" grow>
			<span class={styles.label}>{label}</span>
			{#if description}
				<span class={styles.description}>{description}</span>
			{/if}
		</Stack>
	{/if}
	{#if triggerIcon}
		<Icon class={triggerIcon === ChevronDown ? styles.chevron : ''} icon={triggerIcon} role="none" size="sm" />
	{/if}
</button>
<Popup bind:this={popup} for={id} {...Object.assign({}, defaultPopupOptions, popupOptions)} bind:visible={expanded} onhide={() => menu.collapse()}>
	<Menu bind:this={menu} id={menuId} aria-activedescendant={$focus?.id} aria-labelledby={id} bind:expanded><slot /></Menu>
</Popup>
