import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

/**
 * @template T
 * @typedef {{ id?: string, disabled?: boolean, label: string, value: T }} Item
 **/

/** @typedef {typeof NodeFilter.FILTER_ACCEPT | typeof NodeFilter.FILTER_REJECT | typeof NodeFilter.FILTER_SKIP} FilterResult */
/** @typedef {(element: HTMLElement) => FilterResult} FilterFunc */
/** @typedef {FilterFunc | { acceptNode: FilterFunc }} Filter */

const key = Symbol('focus');

/** @returns {FocusController2} */
export function getFocusController() {
	return getContext(key);
}

/** @param {FocusController2} focus */
function setFocusController(focus) {
	setContext(key, focus);
	return focus;
}

/** @type {(a: number, n: number) => number} */
const mod = (a, n) => {
	const m = a % n;
	return m < 0 ? n + m : m;
};

export class FocusController2 {
	constructor() {
		/** @type {HTMLElement[]} */
		this.stack = [];

		/** @type {HTMLElement | null} */
		this.focusedElement = null;

		const { set: setStore, subscribe } = writable(/** @type {HTMLElement | null | undefined} */ (null));
		this.setStore = setStore;
		this.subscribe = subscribe;

		setFocusController(this);
	}

	/**
	 * @param {HTMLElement} element
	 * @returns {boolean}
	 */
	canFocusElement(element) {
		return element.role !== 'none' && element.role !== 'presentation' && element.ariaDisabled !== 'true' && element.ariaHidden !== 'true';
	}

	/**
	 * @param {HTMLElement} element
	 * @returns {HTMLElement[]}
	 */
	getSiblingElements(element) {
		if (element.parentElement === null) {
			return [];
		}
		return /** @type {HTMLElement[]} */ (Array.from(element.parentElement.children));
	}

	/** @param {HTMLElement | null} [element] */
	set(element = null) {
		if (element !== this.focusedElement) {
			this.focusedElement = element;
			this.setStore(element);
		}
		if (element !== null) {
			requestAnimationFrame(() => {
				element.scrollIntoView({
					behavior: 'auto',
					block: 'nearest',
				});
			});
		}
	}

	/**
	 * @param {HTMLElement} element
	 */
	push(element) {
		if (this.focusedElement === element) {
			return;
		}
		if (this.focusedElement !== null) {
			this.stack.push(this.focusedElement);
		}
		this.set(element);
	}

	pop() {
		this.set(this.stack.pop() ?? null);
	}

	/** @param {number} interval  */
	step(interval) {
		let element = this.focusedElement;
		if (element === null) {
			return;
		}

		const siblings = this.getSiblingElements(element);
		const l = siblings.length;
		if (l === 0) {
			return;
		} else if (l === 1 && element === siblings[0]) {
			return;
		}

		const current = mod(siblings.indexOf(element), l);
		const min = current - l;
		const max = current + l;
		let i = current;
		do {
			if (i < min || i > max) {
				return;
			}
			i = i + interval;
		} while (!this.canFocusElement(siblings[mod(i, l)]));
		this.set(siblings[mod(i, l)]);
	}

	next() {
		this.step(1);
	}

	previous() {
		this.step(-1);
	}

	first() {
		let element = this.focusedElement;
		if (element === null) {
			return;
		}
		const siblings = this.getSiblingElements(element);
		this.set(siblings[0]);
	}

	last() {
		let element = this.focusedElement;
		if (element === null) {
			return;
		}
		const siblings = this.getSiblingElements(element);
		this.set(siblings[siblings.length - 1]);
	}
}
