<script context="module">
	let nextId = 0;
</script>

<script>
	import { Icon } from '@ctm/icons';
	import { ChevronLeft, ChevronRight } from '@ctm/icons/directional';
	import { Tick } from '@ctm/icons/interface';
	import Stack from '$lib/Stack';
	import { getFocusController } from '$lib/experimental/utils/FocusController2.js';
	import styles from './Menu.module.css';
	import Menu, { getMenuContext } from './Menu.svelte';

	export let disabled = false;

	/** @type {string | undefined} */
	export let href = undefined;

	/** @type {IconData | undefined} */
	export let icon = undefined;

	/** @type {string} */
	export let label;

	/** @type {string | undefined} */
	export let description = undefined;

	/** @type {boolean | undefined} */
	export let checked = undefined;

	/** @type {'checkbox' | 'radio' | undefined} */
	export let type = undefined;

	/** @type {(event: HTMLEvent<MouseEvent, HTMLAnchorElement | HTMLButtonElement>) => void} */
	export let onclick = () => {};

	const id = 'menu-item-' + (nextId++).toString();

	/** @type {HTMLElement} */
	let element;
	let expanded = false;

	/** @type {Menu} */
	let submenu;

	$: hasSubMenu = $$slots.default;
	$: subMenuId = `${id}-submenu`;

	const focus = getFocusController();
	const { expandedItem } = getMenuContext();

	/** @param {HTMLEvent<MouseEvent, HTMLAnchorElement | HTMLButtonElement>} event */
	function handleClick(event) {
		if (disabled) {
			event.preventDefault();
			return;
		}
		if (hasSubMenu) {
			event.stopPropagation();
			submenu.toggle();
			return;
		}
		$focus = element;
		onclick(event);
	}

	/** @param {HTMLEvent<PointerEvent, HTMLAnchorElement | HTMLButtonElement>} event */
	function onPointerDown(event) {
		if (!href) {
			event.preventDefault();
		}
	}

	function onPointerMove() {
		if (hasSubMenu && expanded) {
			return;
		}
		$focus = element;
	}

	// $: focused = id === optionId($focus.get());
	$: focused = $focus === element;

	/** @param {string | null} expandedItem */
	function expandedItemDidChange(expandedItem) {
		if (expanded && expandedItem === null) {
			expanded = false;
		}
	}
	$: expandedItemDidChange($expandedItem);
</script>

<li class={styles['item-wrapper']} role="none" class:is-expanded={hasSubMenu ? expanded : undefined} class:has-expanded-sibling={![null, id].includes($expandedItem)}>
	<svelte:element
		this={href ? 'a' : 'button'}
		bind:this={element}
		{id}
		class="{styles.item} {focused ? styles.focused : ''}"
		aria-controls={hasSubMenu ? subMenuId : undefined}
		aria-disabled={disabled}
		aria-expanded={hasSubMenu ? expanded : undefined}
		aria-haspopup={hasSubMenu ? 'menu' : undefined}
		aria-checked={checked}
		{href}
		role="menuitem{type ?? ''}"
		tabindex="-1"
		{...$$restProps}
		on:click={handleClick}
		on:pointerdown={onPointerDown}
		on:pointermove={onPointerMove}
	>
		{#if hasSubMenu && expanded}
			<Icon class={styles.chevron} icon={ChevronLeft} size="sm" />
		{:else if icon}
			<Icon class={styles.icon} {icon} size="md" />
		{/if}
		<Stack gap="2xs" grow>
			<span class={styles.label}>{label}</span>
			{#if description && (!hasSubMenu || !expanded)}
				<span class={styles.description}>{description}</span>
			{/if}
		</Stack>
		{#if hasSubMenu && !expanded}
			<Icon class={styles.chevron} icon={ChevronRight} size="sm" />
		{:else if checked}
			<Icon class={styles.tick} icon={Tick} size="sm" />
		{/if}
	</svelte:element>
	{#if hasSubMenu}
		<Menu bind:this={submenu} id="{id}-submenu" aria-labelledby={id} bind:expanded onexpand={() => ($expandedItem = id)} oncollapse={() => ($expandedItem = null)}><slot /></Menu>
	{/if}
</li>
