<script context="module">
	import { getContext, setContext } from 'svelte';
	import styles from './Menu.module.css';
	import { getFocusController } from '$lib/experimental/utils/FocusController2.js';

	/** @typedef {{ id: string, label: string }} MenuItem */
	/** @typedef {{ collapse: (restoreFocus?: boolean) => void, expandedItem: import('svelte/store').Writable<null | string> }} MenuContext */

	const key = Symbol('menu');
	/** @returns {MenuContext} */
	export function getMenuContext() {
		return getContext(key);
	}

	/** @param {MenuContext} menu */
	function setMenuContext(menu) {
		setContext(key, menu);
		return menu;
	}
</script>

<script>
	import { writable } from 'svelte/store';

	/** @type {string | undefined} */
	export let id = undefined;

	/** @type {string} */
	let className = '';
	export { className as class };

	export let expanded = false;

	const focus = getFocusController();

	/** @type {HTMLElement | null} */
	let menuElement = null;
	export { menuElement as element };

	export let onexpand = () => {};
	export let oncollapse = () => {};

	const expandedItem = writable(null);

	export function expand() {
		if (!menuElement || expanded) {
			return;
		}
		expanded = true;
		const firstChild = /** @type {HTMLElement | null} */ (menuElement.firstElementChild?.firstElementChild ?? null);
		if (firstChild) {
			const children = focus.getSiblingElements(firstChild);
			const selectedChild = children.find((child) => child.ariaChecked === 'true');
			focus.push(selectedChild ?? firstChild);
		}
		onexpand();
	}

	export function collapse() {
		if (!menuElement || !expanded) {
			return;
		}
		expanded = false;
		$expandedItem = null;
		focus.pop();
		oncollapse();
	}

	export function toggle() {
		if (expanded) {
			collapse();
		} else {
			expand();
		}
	}

	setMenuContext({ collapse /*, focus*/, expandedItem });
</script>

<ul bind:this={menuElement} {id} class="{styles.menu} {className}" aria-hidden={!expanded} role="menu" tabindex="-1" {...$$restProps}>
	<slot />
</ul>
