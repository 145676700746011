import MenuComponent from './Menu.svelte';
import MenuButton from './MenuButton.svelte';
import MenuItem from './MenuItem.svelte';
import MenuSeparator from './MenuSeparator.svelte';

const Menu = Object.assign(MenuComponent, {
	Button: MenuButton,
	Item: MenuItem,
	Separator: MenuSeparator,
});

export default Menu;
