<li role="separator" />

<style>
	[role='separator'] {
		background-color: var(--n3);
		height: 1px;
		margin: 4px 0;
		width: 100%;
	}
</style>
